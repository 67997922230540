<template>
    
    <!-- About-->
    <section class="about-section text-center" id="about">
      <div class="container px-4 px-lg-5">
        <div class="row gx-4 gx-lg-5 justify-content-center">
          <div class="col-lg-8">
            <h2 class="text-white-70 mb-4">Pioneering the third generation of NFTs</h2>
            <p class="text-white-50">
                CardanoKeys goes beyond scarcity.<br />
                We experiment with NFTs providing permanent intrinsic value.
                <br /><br />
                70% of revenue will be distributed in our community based on NFTs - forever!<br />
                The initial sale, ongoing projects and future projects, all included!<br />
                Core revenue stems from the initial sale, staking pool, marketplace and loot crates.
            </p>
            <!-- Move this to an "our projects" component-->
            <h3 class="text-white-70 mx-auto mt-2 mb-5">
              Staking Pool
            </h3>
            <p class="text-white-50">
                Our very own staking pool offers competitive fees for long term holders.<br />
                We want to make sure that even people not part of the keymunity may stake to our pool and we won't need to rely on airdrops to incentivize staking.<br />
                <br />
                The pool is now operational at the ticker CKEYS<br />
                <br />
                <a class="btn btn-primary" style="margin-bottom: 3rem" href="https://adapools.org/pool/4647f86d5a076723b985362a71ef667e7c867236a40255b087febdf7">Find us on Adapools!</a>
                
            </p>
            <h3 class="text-white-70 mx-auto mt-2 mb-5">
              Marketplace
            </h3>
            <p class="text-white-50">
                Trading CardanoKeys on our marketplace will become a key mechanic to earn rewards, unlock goodies and take part in the CardanoKeys universe!<br />
                <br />
                Implementing this will require smart contracts and is scheduled for early 2022.<br />
                <br />
                <a class="btn btn-primary disabled" style="margin-bottom: 3rem" href="#">TBD</a>
            </p>
            <h3 class="text-white-70 mx-auto mt-2 mb-5">
              Loot Crates
            </h3>
            <p class="text-white-50">
                Loot Crates are offers exclusively available to holders of CardanoKeys!<br />
                We are able to offer a lot of crates for free or below market value.<br />
                We will achieve this by combining promotions of upcoming projects and using the funds we have available.<br />
                <br />
                <b>Keeping Crates Fair</b><br />
                We reveal the contents of the crates before making them available.<br />
                This does take away the surprise factor but makes sure that everyone involved can make an informed decision.<br />
                <br />
                We have published a full whitepaper about the details of our Loot Crates concept.
            </p>
            <a class="btn btn-primary" style="margin-bottom: 3rem" href="https://docs.google.com/document/d/e/2PACX-1vSJ7zKoiXlp2_AIPADxxt-zEJUFHF4waJFJb6YWaFZAS8Z6uVs2zbv3GMGiF2LQ4GJw3NSe_C-4mGps/pub">Read the Whitepaper!</a>
          </div>
        </div>
      </div>
    </section>
</template>
