<template>
    <section class="contact-section bg-black">
      <div class="container px-4 px-lg-5">
        <div class="row gx-4 gx-lg-5">
          <div class="col-md-4 mb-3 mb-md-0">
            <div class="card py-4 h-100">
              <div class="card-body text-center">
                <i class="fas fa-map-marked-alt text-primary mb-2"></i>
                <h4 class="text-uppercase m-0">Discord Community</h4>
                <hr class="my-4 mx-auto" />
                <div class="small text-black-50">
                  <a
                    class="btn btn-primary"
                    href="https://discord.gg/P4sz3vEQ69"
                    >Click to join!</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-3 mb-md-0">
            <div class="card py-4 h-100">
              <div class="card-body text-center">
                <i class="fas fa-envelope text-primary mb-2"></i>
                <h4 class="text-uppercase m-0">Twitter</h4>
                <hr class="my-4 mx-auto" />
                <div class="small text-black-50">
                  <a
                    class="btn btn-primary"
                    href="https://twitter.com/CardanoKeys"
                    >@CardanoKeys</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-3 mb-md-0">
            <div class="card py-4 h-100">
              <div class="card-body text-center">
                <i class="fas fa-mobile-alt text-primary mb-2"></i>
                <h4 class="text-uppercase m-0">E-Mail</h4>
                <hr class="my-4 mx-auto" />
                <div class="small text-black-50">
                  <a
                    class="btn btn-primary"
                    href="mailto:info@cardanokeys.com"
                    >Info@CardanoKeys.com</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>