<template>
  <button
    id="wallet{{ walletName }}"
    class="walletVendor"
    style="border-bottom: none"
    @click="connectWallet"
  >
    <div class="walletText">
      <img
        crossorigin="anonymous"
        :src="walletLink"
        :alt="walletName + ' Wallet Logo'"
        class="walletVendorLogo"
      /><span
        class="font-size-18 font-weight-600"
        style="opacity: 1; color: var(--textDefaultColor)"
        >{{ walletName }}</span
      >
    </div>
  </button>
</template>

<script>
import { useCookies } from "vue3-cookies";
import { useWalletStore } from "../store/cardanoWallet";

export default {
  name: "WalletVendorButton",
  props: {
    walletName: String,
    walletLink: String,
    walletSubcommand: String,
  },
  setup() {
    const { cookies } = useCookies();
    const walletStore = useWalletStore();
    return { cookies, walletStore };
  },
  data() {
    return {};
  },
  methods: {
    async connectWallet() {
      function setWalletCookie() {
        this.cookies.set("preferredWallet", this.walletSubcommand);
      }

      await this.walletStore
        .connectWallet({
          name: this.walletName,
          subcommand: this.walletSubcommand,
          image: this.walletLink,
        })
        .then(setWalletCookie.bind(this));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.walletVendorLogo {
  width: 36px;
  height: 36px;
  margin-right: 16px;
}
.walletVendor {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: var(--backgroundPrimaryColor);
  cursor: pointer;
  border: none;
  border-radius: 0;
  padding: 13px 19px;
  z-index: 1;
}
.walletText {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
