<template>
    
    <!-- Projects-->
    <section class="projects-section bg-light" id="projects">
      <div class="container px-4 px-lg-5">
        <!-- Featured Project Row-->
        <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
          <div class="col-xl-8 col-lg-7">
            <img
              class="img-fluid mb-3 mb-lg-0"
              src="@/assets/img/infographic.jpg"
              alt="..."
            />
          </div>
          <div class="col-xl-4 col-lg-5">
            <div class="featured-text text-center text-lg-left">
              <h4>Automagic Distribution</h4>
              <p class="text-black-50 mb-0">
                Our distribution of rewards is fully automatic, no interaction required!<br />
                <br />
                We have open sourced the code we use to randomly select winners - our raffles are verifiably random!<br />
              </p>
            <a class="btn btn-primary" style="margin-bottom: 3rem; margin-top: 1rem" href="https://pastebin.com/gapABTNG">Take me to the code!</a>  
            </div>
          </div>
        </div>
        <!-- Project One Row-->
        <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
          <div class="col-lg-6">
            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
            <img
              class="d-block w-100"
              src="@/assets/img/keymunity.png"
              alt="..."
            />
    </div>
    <div class="carousel-item">
            <img
              class="d-block w-100"
              src="@/assets/img/villa_spencer_dialogue.png"
              alt="..."
            />
    </div>
    <div class="carousel-item">
            <img
              class="d-block w-100"
              src="@/assets/img/emoji_story.png"
              alt="..."
            />
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
          </div>
          <div class="col-lg-6">
            <div class="bg-black text-center h-100 project">
              <div class="d-flex h-100">
                <div style="padding-top: 1rem; padding-bottom: 1rem"
                  class="project-text w-100 my-auto text-center text-lg-left"
                >
                  <h4 class="text-white">A positive Community</h4>
                  <p class="mb-0 text-white-50">
                    Our community is one of the most active and positive
                    communities in the entire Cardanosphere.<br />
                    No matter if it's playing fun games, technical knowledge being shared,
                    hints about new projects or just talking about one's day, the self dubbed Keymunity
                    is always welcoming and positive!
                  </p>
                  <hr class="d-none d-lg-block mb-0 ms-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Project Two Row-->
        <div class="row gx-0 justify-content-center">
          <div class="col-lg-6">
            <img
              class="img-fluid"
              src="@/assets/img/100key.gif"
              alt="A delecate key with a spiral of diamonds as key wards. The bow of this key is inspired by the IOHK logo and diamonds sparkle at the outer edges of the bow"
            />
          </div>
          <div class="col-lg-6 order-lg-first">
            <div class="bg-black text-center h-100 project">
              <div class="d-flex h-100">
                <div
                  class="project-text w-100 my-auto text-center text-lg-right"
                >
                  <h4 class="text-white">#100 Club</h4>
                  <p class="mb-0 text-white-50">
                    Due to a mistake in our initial metadata, several keys of the type "Special Diamond" have been minted with the name "CardanoKeys #100".<br />
                    Rather than giving up on the project, holders of this key have decided to band together and form the #100 club.<br />
                    <br />
                    Since the error is purely cosmetic and holders of these keys have celebrated their group we respected their request to not fix this misprint and keep it out there.<br />
                    These keys are fully functional but the #100 club has decided that all rewards towards one of the #100 keys shall be split and disributed among all #100 keys.
                  </p>
                  <hr class="d-none d-lg-block mb-0 me-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>