<template>
  <div>
    <the-masthead> </the-masthead>
    <project-description> </project-description>
    <project-details> </project-details>
    <contact-details></contact-details>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheMasthead from "../components/TheMasthead.vue";
import ProjectDescription from "../components/ProjectDescription.vue";
import ProjectDetails from "../components/ProjectDetails.vue";
import ContactDetails from "../components/ContactDetails.vue";
import TheFooter from "../components/TheFooter.vue";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default {
  name: "App",
  components: {
    TheMasthead,
    ProjectDescription,
    ProjectDetails,
    ContactDetails,
    TheFooter,
  },
  mounted() {
    window.addEventListener("DOMContentLoaded", () => {
      // Navbar shrink function
      var navbarShrink = function () {
        const navbarCollapsible = document.body.querySelector("#mainNav");
        if (!navbarCollapsible) {
          return;
        }
        if (window.scrollY === 0) {
          navbarCollapsible.classList.remove("navbar-shrink");
        } else {
          navbarCollapsible.classList.add("navbar-shrink");
        }
      };

      // Shrink the navbar
      navbarShrink();

      // Shrink the navbar when page is scrolled
      document.addEventListener("scroll", navbarShrink);

      // Activate Bootstrap scrollspy on the main nav element
      const mainNav = document.body.querySelector("#mainNav");
      if (mainNav) {
        new bootstrap.ScrollSpy(document.body, {
          target: "#mainNav",
          offset: 74,
        });
      }

      // Collapse responsive navbar when toggler is visible
      const navbarToggler = document.body.querySelector(".navbar-toggler");
      const responsiveNavItems = [].slice.call(
        document.querySelectorAll("#navbarResponsive .nav-link")
      );
      responsiveNavItems.map(function (responsiveNavItem) {
        responsiveNavItem.addEventListener("click", () => {
          if (window.getComputedStyle(navbarToggler).display !== "none") {
            navbarToggler.click();
          }
        });
      });
    });
  },
};
</script>