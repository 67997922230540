<template>
  <!-- Masthead-->
  <header class="masthead">
    <div
      class="
        container
        px-4 px-lg-5
        d-flex
        h-100
        align-items-center
        justify-content-center
      "
    >
      <div class="d-flex justify-content-center">
        <div class="text-center">
          <h1 class="mx-auto my-0">CardanoKeys</h1>
          <br />
          <h2 class="text-white-70 mx-auto mt-2 mb-5">A Community</h2>
          <h2 class="text-white-70 mx-auto mt-2 mb-5">A Society</h2>
          <h2 class="text-white-70 mx-auto mt-2 mb-5">An Economy</h2>
          <a
            style="margin-top: 1rem"
            class="btn btn-primary disabled"
            href="#sale"
            >SOLD OUT!</a
          >
          <div class="delegation">
            <button
              class="btn btn-primary"
              @click="stakeWithUs"
              v-bind:class="(walletStore.api !== null) ? 'active' : 'disabled'"
            >
              <div
                class="spinner-border"
                role="status"
                v-show="delegationLabel === ''"
              >
                <span class="sr-only"></span>
              </div>
              {{ delegationLabel }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { useWalletStore } from "../store/cardanoWallet";
export default {
  setup() {
    const walletStore = useWalletStore();
    return { walletStore };
  },
  watch: {
    useWalletStore
  },
  computed: {
    delegationLabel() {
      if (!this.walletStore.api){
        return "Connect wallet to stake"
      }
      if (!this.delegationRequest) {
        return "STAKE WITH US!";
      } else if (this.delegationSuccess) {
        return "Success!";
      } else if (this.delegationFailure) {
        this.resetStake();
        return "Something went wrong..";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      delegationRequest: false,
      delegationSuccess: false,
      delegationFailure: false,
    };
  },
  methods: {
    resetStake(){
      setTimeout(function(){
      this.delegationRequest = false
      this.delegationSuccess = false
      this.delegationFailure = false}.bind(this), 5000);
    },
    async stakeWithUs() {
      this.delegationRequest = true;
      this.walletStore
        .stakeWithUs()
        .then(
          function () {
            this.delegationSuccess = true;
          }.bind(this)
        )
        .catch(
          function () {
            this.delegationFailure = true;
          }.bind(this)
        );
    },
  },
};
</script>

<style scoped>
.delegation {
  margin: 20px;
}
.masthead {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 35rem;
  padding: 15rem 0;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.7) 75%,
      #000 100%
    ),
    url("~@/assets/img/landing.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
.masthead h1,
.masthead .h1 {
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2.5rem;
  line-height: 2.5rem;
  letter-spacing: 0.8rem;
  background: linear-gradient(
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.3)
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.masthead h2,
.masthead .h2 {
  max-width: 20rem;
  font-size: 1rem;
  background: linear-gradient(
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.3)
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 1rem !important;
}
@media (min-width: 768px) {
  .masthead h1,
  .masthead .h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
}
@media (min-width: 992px) {
  .masthead {
    height: 100vh;
    padding: 0;
  }
  .masthead h1,
  .masthead .h1 {
    font-size: 6.5rem;
    line-height: 6.5rem;
    letter-spacing: 0.8rem;
  }
  .masthead h2,
  .masthead .h2 {
    max-width: 30rem;
    font-size: 1.25rem;
  }
}
</style>