import {createRouter, createWebHistory} from 'vue-router';
import Home from '../views/Home.vue'
import LootCrates from '../views/LootCrates.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/lootcrates',
        name: 'Loot Crates',
        component: LootCrates
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;