<template>
  <button
    class="connectButton btn btn-primary"
    @mouseover="mostRecentHover = new Date()"
  >
    {{ walletButtonLabel }}
    <div class="vendorList">
      <div class="buttondiv">
        <wallet-vendor-button
          class="vendorbutton"
          v-for="vendor in vendors"
          :key="vendor.subcommand"
          :walletName="vendor.name"
          :walletLink="vendor.image"
          :walletSubcommand="vendor.subcommand"
        ></wallet-vendor-button>
      </div>
    </div>
  </button>
</template>

<script>
// Components
import WalletVendorButton from "./WalletVendorButton.vue";

// Wallet Store
import { useWalletStore } from "../store/cardanoWallet";
import { mapState } from "pinia";

// 3rd party dependencies
import { useCookies } from "vue3-cookies";

export default {
  name: "WalletButton",
  props: {
    msg: String,
  },
  components: {
    WalletVendorButton,
  },

  setup() {
    const { cookies } = useCookies();
    const walletStore = useWalletStore();
    return { cookies, walletStore };
  },
  data() {
    return {
      firstLoad: true,
      mostRecentHover: null,
      vendors: [
        {
          name: "Eternl",
          subcommand: "eternl",
          image: "https://images.jpgstoreapis.com/public/icons/eternl.webp",
        },
        {
          name: "Nami",
          subcommand: "nami",
          image: "https://images.jpgstoreapis.com/public/icons/nami.svg",
        },
        {
          name: "Flint",
          subcommand: "flint",
          image: "https://images.jpgstoreapis.com/public/icons/flint.svg",
        },
        {
          name: "Typhon",
          subcommand: "typhoncip30",
          image:
            "https://images.jpgstoreapis.com/public/icons/typhon-light.svg",
        },
        /*{
          name: "Gero",
          subcommand: "gerowallet",
          image: "https://images.jpgstoreapis.com/public/icons/gerowallet.svg",
        },*/ // Fuck this piece of shit failing to inject its cip30 all the time, not spending hours upon hours to fix a shitty wallet with no userbase
      ],
    };
  },
  computed: {
    ...mapState(useWalletStore, ["api", "activeWallet", "walletBalance"]),
    walletButtonLabel() {
      if (this.firstLoad) {
        return "Checking wallet..";
      }
      if (this.activeWallet !== null) {
        let walletObject = { name: "Unknown" };
        for (var i = 0; i < this.vendors.length; i++) {
          if (this.vendors[i].subcommand === this.activeWallet) {
            walletObject = this.vendors[i];
          }
        }
        if (this.walletBalance !== null) {
          return walletObject.name + " " + this.walletStore.prettyfyLovelace;
        } else {
          return walletObject.name + " Connected";
        }
      } else {
        return "Wallet not connected";
      }
    },
  },
  methods: {},
  async mounted() {
    let preferredVendor = null;
    for (var i = 0; i < this.vendors.length; i++) {
      if (this.vendors[i].subcommand === this.cookies.get("preferredWallet")) {
        preferredVendor = this.vendors[i];
      }
    }
    if (preferredVendor) {
      console.log(
        "Attempting to use previously connected wallet: " + preferredVendor.name
      );
      setTimeout(
        function () {
          console.log(
            "Waited for addons to inject their cip30 into the window, attempting to connect"
          );
          this.walletStore.connectWallet(preferredVendor);
          this.firstLoad = false;
        }.bind(this),
        1000
      );
    } else {
      this.firstLoad = false;
    }

    window.setInterval(() => {
      this.walletStore.checkBalance();
    }, 20000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vendorList {
  display: none;
  position: absolute;
  width: 300px;
  top: 50%;
  
}
.vendorbutton:hover {
  color: #fff;
  background-color: #50817e;
  border-color: #4b7976;
  background: linear-gradient(
    336deg,
    rgba(101, 164, 161, 1) 0%,
    rgba(41, 78, 75, 0.8183648459383753) 100%
  );
  border-radius: 2em;
}
.connectButton {
  background: rgb(1, 85, 40);
  background: linear-gradient(
    222deg,
    rgba(1, 85, 40, 1) 0%,
    rgba(9, 62, 22, 0.9360119047619048) 57%,
    rgba(0, 212, 255, 0.6783088235294117) 100%
  );
  z-index: 1;
  /*padding-left: 10px;*/
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background-repeat: repeat;
  background-size: 100% 300%;
  background-position: 0% 0%;
}
.connectButton:hover {
  background-position: 0% 100%;
}
.connectButton:hover .vendorList {
  display: flex;
}
.vendorList:hover {
  display: flex;
}
.buttondiv {
  background: rgb(101, 164, 161);
  background: linear-gradient(
    156deg,
    rgba(101, 164, 161, 1) 0%,
    rgba(41, 78, 75, 0.8183648459383753) 100%
  );
  width: 100%;
  border-radius: 2em;
}
</style>
