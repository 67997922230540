<template>
  <template-style>
    <the-navigation> </the-navigation>
    <router-view />
  </template-style>
</template>

<script>
import TemplateStyle from "./components/TemplateStyle.vue";

import TheNavigation from "./components/TheNavigation.vue";
export default {
  name: "App",
  components: {
    TheNavigation,
    TemplateStyle,
  },
};
</script>
