<template>
  <div>
Coming Soon!
  </div>
</template>

<script>
import { useWalletStore } from "../store/cardanoWallet";
import { mapState } from "pinia";


export default {
  name: "App",
  components: {
  },
  computed: {
    ...mapState(useWalletStore, ["counter", "activeWallet"]),
  },

  methods: {},
};
</script>