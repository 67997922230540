import { defineStore } from 'pinia';
import cbor from "cbor";
import { Lucid, Blockfrost } from "lucid-cardano";
import converter from "bech32-converting"




export const useWalletStore = defineStore("main", {
    state: () => ({
        api: null,
        activeWallet: null,
        walletBalance: 0,
    }),
    getters: {
        prettyfyLovelace(state) {
            let adastr = "";
            if (state.walletBalance < 1 * 1000000) {
                adastr = "0";
            } else if (state.walletBalance < 10 * 1000000) {
                adastr = parseInt(("" + state.walletBalance).slice(0, 4)) / 1000 + "";
            } else if (state.walletBalance < 100 * 1000000) {
                adastr = parseInt(("" + state.walletBalance).slice(0, 4)) / 100 + "";
            } else if (state.walletBalance < 1000 * 1000000) {
                adastr = parseInt(("" + state.walletBalance).slice(0, 4)) / 10 + "";
            } else if (state.walletBalance < 10 * 1000 * 1000000) {
                adastr = parseInt(("" + state.walletBalance).slice(0, 4)) + "";
            } else if (state.walletBalance < 100 * 1000 * 1000000) {
                adastr = parseInt(("" + state.walletBalance).slice(0, 4)) / 100 + "K";
            } else if (state.walletBalance < 1000 * 1000 * 1000000) {
                adastr = parseInt(("" + state.walletBalance).slice(0, 4)) / 10 + "K";
            } else if (state.walletBalance < 10 * 1000 * 1000 * 1000000) {
                adastr = parseInt(("" + state.walletBalance).slice(0, 4)) + "K";
            } else if (state.walletBalance < 100 * 1000 * 1000 * 1000000) {
                adastr = parseInt(("" + state.walletBalance).slice(0, 4)) / 100 + "M";
            } else if (state.walletBalance < 1000 * 1000 * 1000 * 1000000) {
                adastr = parseInt(("" + state.walletBalance).slice(0, 4)) / 10 + "M";
            } else if (state.walletBalance < 10 * 1000 * 1000 * 1000 * 1000000) {
                adastr = parseInt(("" + state.walletBalance).slice(0, 4)) + "M";
            } else if (state.walletBalance < 100 * 1000 * 1000 * 1000 * 1000000) {
                adastr = parseInt(("" + state.walletBalance).slice(0, 4)) / 100 + "B";
            } else {
                adastr = state.walletBalance; //"-1/12";
            }
            return adastr + " ADA";
        },
    },
    actions: {
        // There are a lot of wallet interaction things here in the store
        // This is not "good", these should be a mixin, but they work for now
        // There's still tons of things to get working and I'll optimize this bit later.
        // For now, it works.
        async stakeWithUs() {
            let bfrost = new Blockfrost('https://cardano-mainnet.blockfrost.io/api/v0', process.env.VUE_APP_BLOCKFROST_PUBLIC);
            console.log(bfrost)
            const lucid = await Lucid.new(
                bfrost,
                "Mainnet"
            );
            console.log(lucid);
            lucid.selectWallet(this.api);
            const rewardAddressHex = "" + await this.api.getRewardAddresses();
            console.log("hex: " + rewardAddressHex)

            let beched = converter('stake').toBech32("0x" + rewardAddressHex)
            console.log("beched: " + beched)
            let rewardAddress = beched;
            console.log("reward: " + rewardAddress);
            const tx = await lucid.newTx()
                .delegateTo(rewardAddress, process.env.VUE_APP_POOLID)
                .complete();

            const signedTx = await tx.sign().complete();

            const txHash = await signedTx.submit();
            return txHash
        },
        disconnectWallet() {
            this.api = null;
            this.activeWallet = null;
            this.walletBalance = null;
        },
        async connectWallet(wallet) {
            let app = await window.cardano[wallet.subcommand].enable();
            if (app) {
                this.$patch({
                    api: app,
                    activeWallet: wallet.subcommand,
                    walletBalance: null,
                });
                this.checkBalance();
            }
        },
        async checkBalance() {
            if (this.activeWallet) {
                let balanceCbor = await this.api.getBalance();
                var typedArray = new Uint8Array(
                    balanceCbor.match(/[\da-f]{2}/gi).map(function (h) {
                        return parseInt(h, 16);
                    })
                );
                let decodedBalance = await cbor.decodeAll(typedArray);
                let balance = 0;
                balance = decodedBalance;

                if (!(!isNaN(balance) && 
                parseInt(Number(balance)) == balance && 
                !isNaN(parseInt(balance, 10)))){
                    balance = balance[0];
                }
                if (!(!isNaN(balance) && 
                parseInt(Number(balance)) == balance && 
                !isNaN(parseInt(balance, 10)))){
                    balance = balance[0];
                }
                if (!(!isNaN(balance) && 
                parseInt(Number(balance)) == balance && 
                !isNaN(parseInt(balance, 10)))){
                    balance = balance[0];
                }
                // Behavior of wallets is EXTREMELY inconsistent, even within a wallet vendor's output
                // At this point it's just giving up hope and guessing until something looks right
                console.log(decodedBalance);
                /*
                switch (this.activeWallet) {
                    case "eternl":
                    case "flint":
                    case "typhoncip30":
                        balance = decodedBalance[0][0]; // eternl returns the full list of utxos..
                        break;
                    case "nami": // nami used to return just the amount, now follows the other convention ... but only on non-production builds of the site? Wtf is this...
                        balance = decodedBalance[0];
                        break;
                    default:
                        balance = decodedBalance[0][0]; // nami just returns the amount of lovelace, maybe that's the default
                }*/
                useWalletStore().$patch({
                    walletBalance: balance,
                });
            } else {
                //console.log("No active wallet, not checking balance");
            }
        },
    }
});